a.external {
  color: $body-color;
  text-decoration: underline dotted $body-color;

  &:hover {
    text-decoration: underline solid;
  }

  &::after {
    display: inline-block;
    content: "";
    background: transparent url("../../../vendors/open-iconic/svgs/external-link.svg") no-repeat;
    background-size: .6em;
    width: .6em;
    height: .6em;
    margin-left: .15em;
    margin-right: .05em;
    vertical-align: baseline;
  }
}
