@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "link";
@import "full_site_search";
@import "home_page";
@import "help_page";
@import "evidence";

////////////////////////////////
    // Header
////////////////////////////////
header {
  background-color: $primary;
}

.sitebar {
  display: grid;
  justify-content: stretch;
  grid-template-columns: 1fr;
  grid-template-rows: 0.2rem 1.5rem 4.8rem 5rem;

  @include media-breakpoint-only(sm) {
    grid-template-rows: 0.2rem 1.5rem 4rem 5rem;
  }

  @include media-breakpoint-only(md) {
    grid-template-columns: 20rem 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 22rem 1fr;
  }

  @include media-breakpoint-up(md) {
    grid-template-rows: 0.5rem 2rem 1rem 6.5rem;
   }
}

.wusm-branding {
  grid-area: 2 / 1 / 3 / 2;
  align-self: center;
  justify-self: start;

  .wusm-logo {
    max-width: 100%;
    height: 1.5rem;
  }

  @include media-breakpoint-up(md) {
    grid-area: 2 / 1 / 3 / 3;

    .wusm-logo {
      height: 1.6rem;
    }
  }
}


.sitebar h1 {
  grid-area: 3 / 1 / 4 / 2;
  align-self: end;
  margin: 0;
  font-size: $h2-font-size;


  .logo {
    height: 2em;
  }

  @include media-breakpoint-up(md) {
    grid-area: -3 / 1 / -1 / 2;
    align-self: start;
    font-size: $h1-font-size;

    .logo {
      height: 2.3em;
    }
  }
}

.site-nav {
  grid-area: 3 / 1 / 4 / 2;
  align-self: start;
  justify-self: end;
  margin-top: 0.4rem;
  z-index: 10;
  font-weight: $font-weight-light;

  .nav-link {
    color: $white;
    padding: 0.15rem 0.5rem;
    border: 2px solid transparent;

    &:hover {
      @include transition($btn-transition);
      background: darken($site-header-background, 10%);
    }

    &.active {
      border: 2px solid white;
    }
  }

  @include media-breakpoint-only(sm) {
    align-self: end;
    margin-top: 0;
    padding: 0.4rem 0.4rem;
  }

  @include media-breakpoint-up(md) {
    grid-area: 2 / -2 / 3 / -1;
    align-self: center;
    justify-self: flex-end;
    margin-top: 0;
    font-weight: $font-weight-normal;

    .nav-link {
      padding: 0.4rem 0.6rem;
    }
  }
}

#site-search-form {
  margin: 0;
  grid-area: 4 / 1 / -1 / 2;
  align-self: center;

  .search-bar {
    width: 100%;

    input, button {
      border-radius: 0px;
      height: 2.5rem;
      border: solid 2px $white;
    }
    button{
      border-left: none;
    }
    input:focus {
      box-shadow: none;
    }
  }

  p {
    font-size: 0.8em;
    margin: 0.2rem 0 0 0;
    color: gray("400");
  }

  @include media-breakpoint-up(md) {
    grid-area: -2 / 2 / -1 / -1;
    align-self: start;
    justify-self: stretch;
    margin-top: 1rem;

    .search-bar {
      input, button {
        height: 3rem;
      }
    }
    p {
      font-size: 1em;
    }
  }
}

////////////////////////////////
    // Site Navbar
////////////////////////////////
#sitenavbar {
  background: $site-header-background;
}


////////////////////////////////
    // Feature viewer
////////////////////////////////
.track-info {
  .icon-info {
    height: .8em;
  }
}

////////////////////////////////
    // PTM content display
////////////////////////////////
.coord {
  font-family:  $font-family-monospace;

  span:not(:last-of-type) {
    margin-right: 1.5em;
  }
}


////////////////////////////////
		//Alerts//
////////////////////////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  @extend .alert-info;
}

.alert-error {
  @extend .alert-danger;
}

////////////////////////////////
		// Main Container //
////////////////////////////////
body > .container {
  padding-top: 1.5em;
  padding-bottom: 5em;
  min-height: 50vh;
}

// Make the main container slightly wider on all devices
body > .container,
nav > .container,
footer > .container,
header > .container {
  @include media-breakpoint-up(xl) {
    max-width: 1200px;
  }
  @include media-breakpoint-only(lg) {
    max-width: 950px;
  }
  @include media-breakpoint-only(md) {
    max-width: 760px;
  }
  @include media-breakpoint-only(sm) {
    max-width: 570px;
  }
}


////////////////////////////////
		// Footer //
////////////////////////////////

.footer {
  background: $site-footer-background;
  .container {
    padding: 1.5rem 0 2rem 0;

    p.copyright {
      margin: 1rem 0 0;
      a {
        color: $light;
        text-decoration: underline;
      }
    }
  }
}

.footer .logo {
  height: 5em;
  max-width: 100%;
}


////////////////////////////////
		//Django Toolbar//
////////////////////////////////

// Display django-debug-toolbar.
// See https://github.com/django-debug-toolbar/django-debug-toolbar/issues/742
// and https://github.com/pydanny/cookiecutter-django/issues/317

[hidden][style="display: block;"] {
  display: block !important;
}
