.ptmcosmos-features {
  margin-bottom: 1em;
}

.ptmcosmos-features .figure {
  border-width: 2px;
  border-radius: 0.5rem;
  margin: 0.25em 0;

  .card-img-top {
    border-top-left-radius: calc(0.5rem - 2px);
    border-top-right-radius: calc(0.5rem - 2px);
  }
  .card-body {
    padding: 0.5rem;
  }
  .card-body p {
    margin: 0;
    color: $primary;
  }
}

.ptmcosmos-features .col-12:not(:first-of-type) h4 {
  margin-top: 2em;
}
