////////////////////////////////
		//Evidence dropdown//
////////////////////////////////

.evidence {
  .manual {
    color: map-get($theme-colors, "info");
  }
  .automatic {
    color: map-get($theme-colors, "secondary");
  }
}

.evidence.dropdown {
  display: inline-block;

  .dropdown-toggle {
    .badge {
      padding-right: 0em;
    }
  }

  .manual-evidence {
    @include button-outline-variant($primary);

    .badge {
      color: inherit;
    }
  }
}

.evidence-menu {
  padding: 1rem;
  font-size: .85rem;

  min-width: 25em;
  max-width: 40em;
  max-height: 50vh;
  overflow-y: auto;

  .title {
    font-size: 1em;
    color: #555;
  }

  p {
    margin: 0;
  }

  .citation:not(:last-of-type) {
    margin-bottom: 1em;
  }
}
